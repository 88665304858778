import React from 'react';
import PropTypes from 'prop-types';
import './memberships.scss';
import MembershipHeader from './components/MembershipHeader';
import MembershipPlan from './components/MembershipPlan';
import { getSilverPlan, getGoldPlan, getPlatinumPlan, getKoaclubPlan, } from '../../../helpers/find-plans.helper';

const Memberships = ({header, silverMembership, goldMembership, platinumMembership, koaclubMembership, plansFromApi}) => {
  const interval = 'month';
  const silverPlan = getSilverPlan(plansFromApi, interval);
  const goldPlan = getGoldPlan(plansFromApi, interval);
  const platinumPlan = getPlatinumPlan(plansFromApi, interval);
  const koaclubPlan = getKoaclubPlan(plansFromApi, interval);

  return (
    <section>
      <MembershipHeader
        title={header.title}
        backgroundImage={header.backgroundImage}
        intro={header.intro}
      />
      <MembershipPlan
        title={silverMembership.title}
        benefits={silverMembership.benefits}
        plan={silverPlan}
      />
      <MembershipPlan
        title={goldMembership.title}
        benefits={goldMembership.benefits}
        plan={goldPlan}
      />
      <MembershipPlan
        title={platinumMembership.title}
        benefits={platinumMembership.benefits}
        plan={platinumPlan}
      />
      <MembershipPlan
        title={koaclubMembership.title}
        benefits={koaclubMembership.benefits}
        plan={koaclubPlan}
        videoBlock={koaclubMembership.videoBlock}
      />
    </section>
  );
};

Memberships.propTypes = {
  header: PropTypes.object,
  silverMembership: PropTypes.object,
  goldMembership: PropTypes.object,
  platinumMembership: PropTypes.object,
  koaclubMembership: PropTypes.object,
  plansFromApi: PropTypes.array,
};

export default Memberships;
