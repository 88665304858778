import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Layout from '../components/Layout/Layout';
import SoundLikeOriginal from '../components/Pricing/SoundLikeOriginal/SoundLikeOriginal';
import MembershipHighlights from '../components/Pricing/MembershipHighlights';
import WhyYouShouldJoin from '../components/WhyYouShouldJoin/WhyYouShouldJoin';
import Instructors from '../components/Pricing/Instructors/Instructors';
import StudentsSaying from '../components/Pricing/StudentsSaying';
import Memberships from '../components/Pricing/Membership/Memberships';
import Plans from '../components/Pricing/Plans/Plans';
import LearningGuitar from "../components/LearningGuitar";
import FlexiblePlans from "../components/Pricing/FlexilbePlans/FlexiblePlans";

export const PricingPageTemplate = ({ frontmatter, plansFromApi }) => {
  return (
    <React.Fragment>
      <SoundLikeOriginal
        smTitle={frontmatter.soundLikeOriginal.smTitle}
        bgTitle={frontmatter.soundLikeOriginal.bgTitle}
        backVideo={frontmatter.soundLikeOriginal.backVideo}
        backgroundImage={frontmatter.soundLikeOriginal.backgroundImage}
        videoURL={frontmatter.soundLikeOriginal.videoURL}
      />
      <WhyYouShouldJoin
        title={frontmatter.whyYouShouldJoin.title}
        description={frontmatter.whyYouShouldJoin.description}
        accessThousands={frontmatter.whyYouShouldJoin.accessThousands}
        personalReviews={frontmatter.whyYouShouldJoin.personalReviews}
        theBestGuidance={frontmatter.whyYouShouldJoin.theBestGuidance}
      />
      <MembershipHighlights
        title={frontmatter.membershipHighlights.title}
        videoURL={frontmatter.membershipHighlights.videoURL}
        list={frontmatter.membershipHighlights.items}
      />
      <Instructors
        title={frontmatter.instructors.title}
        instructorsList={frontmatter.instructors.instructors}
        backgroundImage={frontmatter.instructors.backgroundImage}
      />
      <StudentsSaying
        title={frontmatter.studentsSaying.title}
        studentsComments={frontmatter.studentsSaying.studentsComments}
      />
      <Memberships
        header={frontmatter.memberships.header}
        silverMembership={frontmatter.memberships.silverMembership}
        goldMembership={frontmatter.memberships.goldMembership}
        platinumMembership={frontmatter.memberships.platinumMembership}
        koaclubMembership={frontmatter.memberships.koaclubMembership}
        plansFromApi={plansFromApi}
      />
      <Plans
        smTitle={frontmatter.plans.smTitle}
        bgTitle={frontmatter.plans.bgTitle}
        bronze={frontmatter.plans.bronze}
        silver={frontmatter.plans.silver}
        gold={frontmatter.plans.gold}
        platinum={frontmatter.plans.platinum}
        koaclub={frontmatter.plans.koaclub}
        plansFromApi={plansFromApi}
      />
      <LearningGuitar
        title={frontmatter.learningGuitar.title}
        imageContainer={frontmatter.learningGuitar.imageContainer}
        lists={frontmatter.learningGuitar.lists}
      />
      <FlexiblePlans
        title={frontmatter.flexiblePlans.title}
        silver={frontmatter.flexiblePlans.silver}
        gold={frontmatter.flexiblePlans.gold}
        platinum={frontmatter.flexiblePlans.platinum}
        koaclub={frontmatter.flexiblePlans.koaclub}
        plansFromApi={plansFromApi}
      />
    </React.Fragment>
  );
};

PricingPageTemplate.propTypes = {
  frontmatter: PropTypes.object,
  plansFromApi: PropTypes.array,
};

const PricingPage = ({ data }) => {
  const plansFromApi = data.allPlansJson
    && data.allPlansJson.edges
    && Array.isArray(data.allPlansJson.edges)
    && data.allPlansJson.edges.map((item) => item.node);

  const { frontmatter } = data.markdownRemark;

  return (
    <Layout  title="Pricing">
      <PricingPageTemplate frontmatter={frontmatter} plansFromApi={plansFromApi} />
    </Layout>
  );
};

PricingPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};

export default PricingPage;

export const pageQuery = graphql`
  query PricingPage {
    markdownRemark(frontmatter: { templateKey: { eq: "pricing-page" } }) {
      frontmatter {
        soundLikeOriginal {
          smTitle
          bgTitle
          backVideo
          backgroundImage {
            childImageSharp {
              fluid(maxWidth: 800) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          videoURL
        }
        whyYouShouldJoin {
          title
          description
          accessThousands {
            title
            description
            slider {
              imageContainer {
                alt
                src {
                  childImageSharp {
                    fluid(maxWidth: 650) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
          }
          personalReviews {
            title
            description
            videoURL
          }
          theBestGuidance {
            title
            description
            videoURL
          }
        }
        membershipHighlights {
          title
          videoURL
          items
        }
        instructors {
          title
          backgroundImage {
            childImageSharp {
              fluid(maxWidth: 1280) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          instructors {
            name
            description
            photo {
              childImageSharp {
                fluid(maxWidth: 400) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
        studentsSaying {
          title
          studentsComments {
            name
            state
            comment
            videoURL
            photo {
              childImageSharp {
                fluid(maxWidth: 400) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
        memberships {
          header {
            title
            intro
            backgroundImage {
              childImageSharp {
                fluid(maxWidth: 1280) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          silverMembership {
            title
            benefits {
              title
              description
              imageContainer {
                alt
                src {
                  childImageSharp {
                    fluid(maxWidth: 100) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                  extension
                  publicURL
                }
              }
            }
          }
          goldMembership {
            title
            benefits {
              title
              description
              imageContainer {
                alt
                src {
                  childImageSharp {
                    fluid(maxWidth: 100) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                  extension
                  publicURL
                }
              }
            }
          }
          platinumMembership {
            title
            benefits {
              title
              description
              imageContainer {
                alt
                src {
                  childImageSharp {
                    fluid(maxWidth: 100) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                  extension
                  publicURL
                }
              }
            }
          }
          koaclubMembership {
            title
            videoBlock {
              title
              videoURL
            }
            benefits {
              title
              description
              imageContainer {
                alt
                src {
                  childImageSharp {
                    fluid(maxWidth: 100) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                  extension
                  publicURL
                }
              }
            }
          }
        }
        plans {
          smTitle
          bgTitle
          bronze {
            topList
            bottomList
          }
          silver {
            topList
            bottomList
          }
          gold {
            topList
            bottomList
          }
          platinum {
            topList
            bottomList
          }
          koaclub {
            topList
            bottomList
          }
        }
        learningGuitar {
          title
          lists {
            title
            items 
          }
          imageContainer {
            alt
            src {
              childImageSharp {
                fluid(maxWidth: 650) {
                  ...GatsbyImageSharpFluid
                }
              }
              extension
              publicURL
            }
          }
        }
        flexiblePlans {
          title
          silver {
            description
            imageContainer {
              alt
              src {
                childImageSharp {
                  fluid(maxWidth: 100) {
                    ...GatsbyImageSharpFluid
                  }
                }
                extension
                publicURL
              }
            }
          }
          gold {
            description
            imageContainer {
              alt
              src {
                childImageSharp {
                  fluid(maxWidth: 100) {
                    ...GatsbyImageSharpFluid
                  }
                }
                extension
                publicURL
              }
            }
          }
          platinum {
            description
            imageContainer {
              alt
              src {
                childImageSharp {
                  fluid(maxWidth: 100) {
                    ...GatsbyImageSharpFluid
                  }
                }
                extension
                publicURL
              }
            }
          }
          koaclub {
            description
            imageContainer {
              alt
              src {
                childImageSharp {
                  fluid(maxWidth: 100) {
                    ...GatsbyImageSharpFluid
                  }
                }
                extension
                publicURL
              }
            }
          }
        }
      }
    }
    allPlansJson {
      edges {
        node {
          id
          alias
          title
          interval
          code
          amount
        }
      }
    }
  }
`;
