import React from 'react';
import PropTypes from 'prop-types';
import PreviewCompatibleImage from './Common/PreviewCompatibleImage';
import Fade from 'react-reveal/Fade';

const animationDelay = 300;
const distance = '20%';

const LearningGuitar = ({ title, imageContainer, lists}) => (
  <section>
    <Fade bottom delay={animationDelay} distance={distance}>
      <div className="container py-7">
        <div className="row align-items-center">
          <div className="col col-12 mb-3">
            <h2 className="text-primary text-center"> {title} </h2>
          </div>
          <div className="col-12 col-md-7 mb-4 mb-lg-0">
            {imageContainer && imageContainer.src && (<PreviewCompatibleImage
              imageInfo={{
                image: imageContainer.src,
                alt: imageContainer.alt,
              }}
            />)}
          </div>
          <div className="col col-12 col-md-5">
            {
              lists && lists.map((list, i) => (
                list && (<section key={i} className="mb-4">
                  <h3 className="h5 font-weight-bold">{list.title}</h3>
                  <ul className="m-0 p-0">
                    {
                      list.items && list.items.map((item, i) => (
                        <li className="icon-note mb-1" key={i}>{item}</li>
                      ))
                    }
                  </ul>
                </section>
                ))
              )
            }
          </div>
        </div>
      </div>
    </Fade>
  </section>
);

LearningGuitar.propTypes = {
  title: PropTypes.string,
  imageContainer: PropTypes.object,
  lists: PropTypes.array,
};

export default LearningGuitar;
