import React from 'react';
import PropTypes from 'prop-types';
import './flexiblePlans.scss';
import { getSilverPlan, getGoldPlan, getPlatinumPlan, getKoaclubPlan, } from '../../../helpers/find-plans.helper';
import PreviewCompatibleImage from '../../Common/PreviewCompatibleImage';

const FlexiblePlans = ({ title, plansFromApi, silver, gold, platinum, koaclub }) => {
  const interval = 'month';
  const silverPlan = getSilverPlan(plansFromApi, interval);
  const goldPlan = getGoldPlan(plansFromApi, interval);
  const platinumPlan = getPlatinumPlan(plansFromApi, interval);
  const koaclubPlan = getKoaclubPlan(plansFromApi, interval);
  const plansData = [
    { ...silverPlan, ...silver },
    { ...goldPlan, ...gold },
    { ...platinumPlan, ...platinum },
    { ...koaclubPlan, ...koaclub },
  ];

  return (
    <section className="pt-7 pb-4 flexible-plans">
      <div className="container">
        <div className="row">
          <div className="col col-12 mb-6">
            <h2 className="text-primary text-center">{title}</h2>
          </div>
          {plansData.map((plan, i) => (
            <div key={i} className="col col-12 col-sm-6 col-lg-3 mb-5 mb-lg-">
              <section className="text-center p-3 px-lg-2 card-transparent h-100 d-flex flex-column align-items-center">
                {plan && plan.imageContainer &&(<PreviewCompatibleImage
                  imageInfo={{
                    className: 'card-img-small mb-4',
                    image: plan.imageContainer.src,
                    alt: plan.imageContainer.alt,
                  }}
                />)}
                <h3 className="text-uppercase mb-3">{plan.title}</h3>
                <p className="pb-2 small">{plan.description}</p>
                <div className="pt-5 px-3 mt-auto border-top-dark w-100">
                  <a
                    href={`${process.env.TOTALLY_LESSONS_URL}/buy?type=plan&id=${plan.id}`}
                    className="btn-tg btn-tg-sale w-100"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Buy now
                  </a>
                </div>
              </section>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

FlexiblePlans.propTypes = {
  title: PropTypes.string,
  plansFromApi: PropTypes.array,
  silver: PropTypes.object,
  gold: PropTypes.object,
  platinum: PropTypes.object,
  koaclub: PropTypes.object,
};

export default FlexiblePlans;
