import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import './plans.scss';
import { formatPrice } from '../../../helpers/format-price.helper';
import { getSilverPlan, getGoldPlan, getPlatinumPlan, getKoaclubPlan, } from '../../../helpers/find-plans.helper';

const Plans = ( {smTitle, bgTitle, plansFromApi, bronze, silver, gold, platinum, koaclub}) => {

  const [plansPeriod, setPlansPeriod] = useState('month');

  const silverPlan = getSilverPlan(plansFromApi, plansPeriod);
  const goldPlan = getGoldPlan(plansFromApi, plansPeriod);
  const platinumPlan = getPlatinumPlan(plansFromApi, plansPeriod);
  const koaclubPlan = getKoaclubPlan(plansFromApi, plansPeriod);

  const plansMonthData = [
    {...bronze, ...{title: 'Bronze'}},
    {...silverPlan, ...silver },
    {...goldPlan, ...gold },
    {...platinumPlan, ...platinum },
    {...koaclubPlan, ...koaclub },
  ];

  const plansYearData = [
    {...bronze, ...{title: 'Bronze'}},
    {...silverPlan, ...silver },
    {...goldPlan, ...gold },
    {...platinumPlan, ...platinum },
    {...koaclubPlan, ...koaclub },
  ];

  const cookPlansData = () => plansPeriod === 'month' ? plansMonthData : plansYearData;

  return (
    <section className="position-relative plans" id="plans">
      <div className="container pt-7">
        <div className="row">
          <div className="col text-center">
            <h1 className="h1">
              <span className="subtitle font-weight-bold">{smTitle}</span>
              <br />
              {bgTitle}
            </h1>
          </div>
        </div>
        <ul className="nav nav-tabs justify-content-center">
          <li className="nav-item">
            <button className={`nav-link ${plansPeriod==='month' ? "active" : ""}`} onClick={()=>setPlansPeriod('month')}>Monthly</button>
          </li>
          <li className="nav-item">
            <button className={`nav-link ${plansPeriod==='year' ? "active" : ""}`} onClick={()=>setPlansPeriod('year')}>Yearly</button>
          </li>
        </ul>

        <div className="row mt-9 justify-content-center">
          <PlansView plansData={cookPlansData()} plansPeriod={plansPeriod}/>
        </div>
      </div>
    </section>
  );
};

Plans.propTypes = {
  smTitle: PropTypes.string,
  bgTitle: PropTypes.string,
  plansFromApi: PropTypes.array,
  bronze: PropTypes.object,
  silver: PropTypes.object,
  gold: PropTypes.object,
  platinum: PropTypes.object,
  koaclub: PropTypes.object,
};

export default Plans;


const ShowPromo = React.memo(({promoCodes, setPromoCodes, plan, plansPeriod}) => {
  const [input, setInput] = useState(promoCodes[plan.code]);
  const [showPromoInput, setShowPromoInput] = useState(!!promoCodes[plan.code]);

  useEffect(()=>{
    setInput('');
    setShowPromoInput(false);
  },[plansPeriod]);

  const OnChange = (e) =>{
    e.persist();
    setInput(e.target.value);
    setPromoCodes((previousState) => ({...previousState, [e.target.name]: e.target.value}));
  };

  return (
    <React.Fragment>
      { showPromoInput ?
        <React.Fragment>
          <div className="promo-input-wrapper">
            { input && <label className="label-promo-input" htmlFor={plan.code}>Promo code</label> }
            < input
              maxLength="100"
              name={plan.code}
              className="mb-4 promo-input"
              onChange={OnChange} type = "text"
              value={input}
              placeholder="Enter promo code"
            />
          </div>
        </React.Fragment>
        :
        <button className="mb-4 btn-tg-promo" onClick={()=>setShowPromoInput(!showPromoInput)}>Use promo code</button>
      }
    </React.Fragment>
  )});

const PlansView = React.memo(({plansData, plansPeriod}) => {

  const promoCodesInitialValues = ()=>
    plansData.filter(plan=>!!plan.code).reduce((accumulator, plan)=> ({...accumulator,[plan.code]: ''}),{});

  const [promoCodes, setPromoCodes] = useState(promoCodesInitialValues());

  useEffect(()=>{
    setPromoCodes(promoCodesInitialValues());
    },[plansPeriod]);

  function getMosPopularClass(plan) {
    if (plan.code === 'platinum') {
      return 'most-popular';
    } else if (plan.code === 'koaclub') {
      return 'serious';
    }

    return '';
  }

  return (
    <React.Fragment>
      {
        plansData.map((plan, i) => (
          <div key={i} className={`col col-12 col-md-6 col-lg-4 col-xl px-xl-2 mb-9`}>
            <section className={`card-plan px-3 h-100 ${getMosPopularClass(plan)}`}>
              <div className="card-title-container">
                {plan.code === 'platinum' && <p className="intro">Most popular</p>}
                {plan.code === 'koaclub' && <p className="intro">For the serious student</p>}
                <h3 className="card-title text-uppercase mb-0">{plan.title}</h3>
              </div>
              <ul className="list-unstyled mb-2">
                {
                  plan.topList && plan.topList.map((item, i) => (
                    <li key={i} className="mb-2">{item}</li>
                  ))
                }
              </ul>
              <div className="price-wrapper mb-5">
                <p className="price mb-0">
                  {
                    plan.amount
                      ? <React.Fragment>
                        <span className="amount"><sup>$</sup>{formatPrice(plan.amount)}</span>
                        <span className="ml-1 mt-1">/ {plan.interval}</span>
                      </React.Fragment>
                      : <span className="amount">FREE</span>
                  }
                </p>
              </div>
              {
                plan.amount
                  ?
                  <React.Fragment>
                   <ShowPromo key={plan.code} promoCodes={promoCodes} setPromoCodes={setPromoCodes} plan={plan} plansPeriod={plansPeriod}/>
                   <a
                     href={`${process.env.TOTALLY_LESSONS_URL}/buy?type=plan&id=${plan.id}&coupon=${promoCodes[plan.code]}`}
                     className={`d-block btn-tg btn-tg-sale ${plansPeriod === 'year' ? 'mb-2' : 'mb-4' }`}
                     target="_blank"
                     rel="noopener noreferrer"
                   >BUY NOW</a>
                    { plansPeriod === 'year' && (
                      <a
                        href={`/gifts?type=membership&id=${plan.id}`}
                        className="d-block btn-tg btn-tg-secondary mb-4"
                      >Send as Gift</a>
                    )}
                  </React.Fragment>
                  :
                  <a
                    href={`${process.env.TOTALLY_LESSONS_URL}/sign-up`}
                    className="btn-tg btn-tg-sale mb-4 mt-8"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Sign up
                  </a>
              }
              {plan.code === 'gold' && <p className="mb-2"><strong>Everything in SILVER</strong></p>}
              {plan.code === 'platinum' && <p className="mb-2"><strong>Everything in GOLD</strong></p>}
              {plan.code === 'koaclub' && <p className="mb-2"><strong>Everything in PLATINUM</strong></p>}
              <ul className="list-unstyled list-plus">
                {
                  plan.bottomList && plan.bottomList.map((item, i) => (
                    <li key={i} className="mb-2">{item}</li>
                  ))
                }
              </ul>
            </section>
          </div>
    ))}
    </React.Fragment>
  );
});
