import React from 'react';
import PropTypes from 'prop-types';
import laptop from '../../../img/laptop.png';
import MemoVideoPlayer from '../../Common/VideoPlayer/VideoPlayer';

const Headline = ({
  title,
  description,
  videoURL,
  buttonLink,
}) => (
  <section className="row py-5">
    <div className="col col-12 col-md-7 mb-4 mb-md-0">
      <div className="laptop">
        <img className="w-100" src={laptop} alt="Totally Guitars"/>
        <div className="laptop-video-container">
          <MemoVideoPlayer video={{url: videoURL, platform: 'vimeo'}} />
        </div>
      </div>
    </div>
    <div className="col col-12 col-md-5 d-flex align-items-center">
      <div>
        <h3 className="text-primary mb-3">{title}</h3>
        <p className="mb-4">{description}</p>
        { buttonLink && <a href={buttonLink.path} className="mt-4 btn-tg btn-tg-sale" target="_blank" rel="noopener noreferrer">{buttonLink.label}</a> }
      </div>
  </div>
  </section>
);

Headline.propTypes = {
  title: PropTypes.string,
  videoURL: PropTypes.string,
  description: PropTypes.string,
  buttonLink: PropTypes.object,
};

export default Headline;
